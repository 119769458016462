<template>
  <div class="">
    <p class="font-16 color-35" v-html="source.text"></p>
    <p class="font-12 mg-t-1">{{ source.createdAt | formatDateTHFullTime }}</p>
    <hr class="mg-y-3" />
  </div>
</template>
<script>
export default {
  props: ["source"],
};
</script>
