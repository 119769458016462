<template>
  <div>
    <div
      class="card-assessment-plan"
      v-for="(assessment, index) in data"
      :key="index"
    >
      <el-tooltip
        class="item"
        effect="dark"
        :content="assessment.companyName"
        placement="right-end"
      >
        <p>
          {{ assessment.planName }}
        </p>
      </el-tooltip>
      <div class="box-icon">
        <i
          class="fas fa-eye"
          @click="
            $router.push(`/matching/assessment-plan/detail/${assessment._id}`)
          "
        ></i>
        <i
          class="fas fa-edit"
          @click="
            $router.push(`/matching/assessment-plan/edit/${assessment._id}`)
          "
        ></i>
        <i
          class="fas fa-trash-alt"
          @click="$emit('openDialogDelete', assessment._id)"
        ></i>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
