<template>
  <DefaultTemplate
    :footer="true"
    v-loading.lock="loading"
    element-loading-text="กำลังโหลดข้อมูล..."
    element-loading-spinner="el-icon-loading"
    id="assessment-plan"
  >
    <div>
      <h2 class="mg-y-2">Assessment Plan</h2>
      <el-row
        :gutter="15"
        class="mg-y-5 flex-wrap"
        type="flex"
        align="middle"
        justify="end"
      >
        <el-col :span="24" :md="12" :xl="16" class="text-right">
          <el-button
            round
            @click="openHistory()"
            class="mg-t-2 font-14 button-history"
          >
            <i class="fas fa-history"></i> History log</el-button
          >

          <el-button
            type="warning"
            class="button-radius mg-t-2 font-14"
            @click="$router.push(`/matching/assessment-plan/add`)"
            >+ Create Agenda</el-button
          >
        </el-col>
      </el-row>
      <el-row :gutter="15">
        <el-col :span="24">
          <GroupAssessmentPlan
            :data="fetchData"
            @openDialogDelete="openDialogDelete"
          />
          <div class="text-center mg-y-4">
            <el-pagination
              :hide-on-single-page="true"
              @size-change="handleSizeChange"
              @current-change="changePage"
              :current-page.sync="page"
              :page-size="limit"
              layout="prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </el-col>
      </el-row>
    </div>

    <el-dialog :visible.sync="dialogDelete" width="45%" top="22vh">
      <template slot="title">
        <p class="font-28 text-center mg-b-less color-main-1">ยืนยันการลบ</p>
      </template>
      <p class="text-center mg-t-less font-16 color-main-1">
        กรุณาตรวจสอบก่อนลบข้อมูล
      </p>

      <span slot="footer" class="dialog-footer is-flex js-between">
        <el-button @click="dialogDelete = false" class="button-radius">
          <span class="font-14"> ยกเลิก </span>
        </el-button>
        <el-button
          type="warning"
          @click="submitDeleteAssessment()"
          class="button-radius"
        >
          <span class="font-14"> ยืนยัน </span>
        </el-button>
      </span>
    </el-dialog>
    <el-drawer
      class="drawer-history"
      :visible.sync="drawerHistory"
      :before-close="closeHistory"
    >
      <div class="box-header">
        <div>
          <span> History log </span>
        </div>
      </div>
      <div id="custom-collapse" v-if="fetchHistory.length > 0">
        <virtual-list
          v-if="fetchHistory.length > 0"
          class="detail-log list-infinite scroll-touch"
          :data-key="'_id'"
          :data-sources="fetchHistory"
          :data-component="itemComponent"
          v-on:tobottom="scrollToBottom"
          :item-class="'list-item-infinite'"
        >
          <div slot="footer" class="text-center" v-if="loadingHistory">
            <i class="el-icon-loading"></i>
          </div>
        </virtual-list>
      </div>
      <p
        class="font-20 color-75 text-center mg-y-5 pd-t-1"
        v-if="fetchHistory.length == 0"
      >
        ไม่มีข้อมูล
      </p>
    </el-drawer>
  </DefaultTemplate>
</template>

<script>
import "@/mixin/DefaultTemplate";
import { HTTP } from "@/service/axios";
import GroupAssessmentPlan from "@/components/assessment-plan/GroupAssessmentPlan";
import VirtualList from "vue-virtual-scroll-list";
import History from "@/components/history/History";

import { mapState } from "vuex";

export default {
  components: {
    GroupAssessmentPlan,
    "virtual-list": VirtualList,
  },
  computed: {
    ...mapState({
      _token: (state) => state._token,
    }),
  },
  mounted() {
    this.getAssessmentPlan();
  },
  data() {
    return {
      loading: true,
      loadingTable: false,
      filter: {
        search: "",
      },
      fetchData: [],
      page: 1,
      skip: 0,
      limit: 10,
      total: 20,
      idActive: "",
      drawerHistory: false,
      fetchHistory: [],
      dialogDelete: false,
      loadingHistory: false,
      totalAll: 0,
      skipHistory: 0,
      limitHistory: 10,
      pageNowHistory: 1,
      itemComponent: History,
    };
  },
  methods: {
    getAssessmentPlan() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;

      let obj = {
        search: this.filter.search,
        skip: this.skip,
        limit: this.limit,
      };

      HTTP.post(`matching/assessment/all`, obj)
        .then((res) => {
          if (res.data.success) {
            this.fetchData = res.data.obj;
            this.total = res.data.total;
          }
        })
        .catch((e) => {
          if (typeof e.response != "undefined") {
            if (e.response.data.status == 401) {
              this.checkAuth();
            }
          }
        })
        .finally(() => {
          this.loading = false;
          this.loadingTable = false;
        });
    },
    handleSizeChange(val) {
      this.limit = val;
      this.changePage(1);
      this.getAssessmentPlan();
    },
    changePage(page) {
      this.loadingTable = true;
      this.page = page;
      this.skip = page * this.limit - this.limit;
      this.getAssessmentPlan();
    },

    openDialogDelete(id) {
      this.idActive = id;
      this.dialogDelete = true;
    },

    openHistory() {
      this.drawerHistory = true;
      this.getHistoryLog();
    },
    getHistoryLog(skip, limit, type) {
      let obj = {
        type: 3,
        skip: skip != undefined ? skip : this.skipHistory,
        limit: limit != undefined ? limit : this.limitHistory,
      };
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      HTTP.post(`user/history/manage`, obj)
        .then((res) => {
          if (res.data.success) {
            if (type != "scroll") {
              this.fetchHistory = res.data.obj;
            } else {
              res.data.obj.forEach((row) => {
                this.fetchHistory.push({ ...row });
              });
            }
            this.totalAll = res.data.total;
          }
        })
        .catch((e) => {
          if (typeof e.response != "undefined") {
            if (e.response.data.status == 401) {
              this.checkAuth();
            } else {
              this.alertCatchError(e);
            }
          }
        });
    },
    closeHistory() {
      this.drawerHistory = false;
      this.skipHistory = 0;
      this.pageNowHistory = 1;
    },
    async scrollToBottom() {
      if (this.fetchHistory.length < this.totalAll) {
        this.skipHistory = this.pageNowHistory * this.limitHistory;
        this.loadingHistory = true;
        await this.getHistoryLog(this.skipHistory, this.limitHistory, "scroll");
        this.pageNowHistory++;
      } else {
        this.loadingHistory = false;
      }
    },
    submitDeleteAssessment() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      HTTP.delete(`matching/assessment/delete/` + this.idActive)
        .then((res) => {
          if (res.data.success) {
            this.$notify({
              title: "ลบ Assessment Plan สำเร็จ!",
              type: "success",
              customClass: "success",
            });
            this.idActive = "";
            this.dialogDelete = false;
            this.getAssessmentPlan();
          }
        })
        .catch((e) => {
          console.log("fetchTest", e);
        });
    },
  },
};
</script>
